<template>
    <v-container class="pa-0">
        <label class="font-weight-bold">
            How often should we write this Amp?
        </label>
        <v-radio-group v-model="selectedValue" @change="onChange">
            <v-radio
                label="Every day"
                :value="1"
                :disabled="!customFrequencyEnabled"
            />

            <v-radio
                label="Once a week"
                :value="7"
                :disabled="!customFrequencyEnabled"
            />

            <v-radio label="Once a month" :value="31" />

            <v-radio
                class="align-start align-sm-center"
                :disabled="!customFrequencyEnabled"
                value="other"
                @change="enableCustomFrequency"
            >
                <template #label>
                    <div
                        v-if="regularFrequencyEnabled"
                        class="d-flex flex-column flex-sm-row align-sm-center justify-sm-start flex-wrap flex-sm-nowrap"
                    >
                        Other
                    </div>
                    <div
                        v-else
                        class="d-flex flex-column flex-sm-row align-sm-center justify-sm-start flex-wrap flex-sm-nowrap"
                    >
                        <span class="mr-2 mb-2 mb-sm-0">One Amp every</span>

                        <a-text-input
                            v-model="otherFrequencySelected"
                            dense
                            hide-details
                            type="number"
                            min="2"
                            max="30"
                            hide-label
                            @change="onChange"
                            @keypress="filterNonNumeric"
                        />

                        <span
                            class="mx-0 mx-sm-2 my-2 my-sm-0"
                            style="width: fit-content"
                        >
                            days
                        </span>
                    </div>
                </template>
            </v-radio>
        </v-radio-group>
    </v-container>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { ATextInput } from '@/components/AForm/Inputs/ATextInput';

const AuthorRequestFrequencySelectorProps = Vue.extend({
    name: 'AuthorRequestFrequencySelector',
    props: {
        value: {
            type: Number as PropType<number>,
            required: true
        },
        customFrequencyEnabled: {
            type: Boolean,
            default() {
                return false;
            }
        }
    }
});

@Component({
    components: {
        ATextInput
    }
})
export default class AuthorRequestFrequencySelector extends AuthorRequestFrequencySelectorProps {
    selectedValue: number | string = 31;
    otherFrequencySelected: number | string = 2;

    get regularFrequencyEnabled() {
        return [1, 7, 31].includes(Number(this.selectedValue));
    }

    mounted() {
        if (
            (this.value > 1 && this.value < 7) ||
            (this.value > 7 && this.value < 31)
        ) {
            this.selectedValue = 'other';
            this.otherFrequencySelected = this.value;
        } else {
            this.selectedValue = this.value;
        }
    }

    enableCustomFrequency() {
        if (this.selectedValue !== 'other') {
            this.selectedValue = 2;
        }
    }

    onChange(selectedFrequency: string) {
        if (
            isNaN(Number(selectedFrequency)) ||
            parseInt(selectedFrequency) < 1 ||
            parseInt(selectedFrequency) > 31 ||
            selectedFrequency === ''
        ) {
            selectedFrequency = '2';
            this.otherFrequencySelected = 2;
        }

        this.$emit('input', parseInt(selectedFrequency));
    }

    filterNonNumeric(event: KeyboardEvent) {
        const allowedKeys = ['Backspace', 'Delete'];
        if (!/^\d$/.test(event.key) && !allowedKeys.includes(event.key)) {
            event.preventDefault();
        }
    }
}
</script>
